<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2" @click="abrirFiltros" />
              <Button label="Desativar Implantação" icon="pi pi-trash" class="p-button-danger"
                @click="confirmDeleteSelected" :disabled="!selectImplantacoes || !selectImplantacoes.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Implantaçoes -->
        <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes" dataKey="id" :paginator="true"
          :rows="20" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Implantações</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column select style="width: 5rem" />

          <Column field="id" header="ID" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="id_exidracao" header="Extração" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Extração</span>
              {{ slotProps.data.id_fila }}
            </template>
          </Column>
          <Column field="razao_social" header="Empresa" :sortable="true" headerStyle="width:45%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Empresa</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="unidade" header="Unidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.unidade }}
            </template>
          </Column>

          <Column field="mes_referencia" header="Mes Referencia" :sortable="true"
            headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Mes Referencia</span>
              {{ slotProps.data.mes_referencia }}
            </template>
          </Column>

          <Column field="descricao_insumo" header="Insumo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Insumo</span>
              {{ slotProps.data.descricao_insumo }}
            </template>
          </Column>

          <Column field="descricao_concessionaria" header="Concessionaria" :sortable="true"
            headerStyle="width:14%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Concessionaria</span>
              {{ slotProps.data.descricao_concessionaria }}
            </template>
          </Column>




          <Column field="status" header="Status" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              {{ slotProps.data.status }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarFatura(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarFaturasModal(slotProps.data)" />
            </template>
          </Column>

        </DataTable>

        <Dialog v-model:visible="ModalFiltros" :style="{ width: '600px' }" header="Filtros" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="id_empresa">Empresa</label>
            <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa" :options="tipos_empresas"
              optionLabel="label" optionValue="value" placeholder="Selecione uma empresa"></Dropdown>
          </div>
          <div class="field">
            <label for="unidade">Unidade</label>
            <InputText id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

          </div>
          <div class="field">
            <label for="mes_referencia">Mês Referencia</label>
            <Calendar id="mes_referencia"  view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia" required="true" autofocus />

          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="this.ModalFiltros = false" />
            <Button label="Pesquisar" icon="pi pi-check" class="p-button-success p-button-sm" @click="pesquisar()" />
          </template>
        </Dialog>

        <Dialog v-model:visible="ModalFatura" :style="{ width: '90%' }" header="Fatura" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="id_empresa">Empresa</label>
            <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa" :options="tipos_empresas"
              optionLabel="label" optionValue="value" placeholder="Selecione uma empresa"></Dropdown>
          </div>
          <div class="field">
            <label for="unidade">Unidade</label>
            <InputText id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

          </div>
          <div class="field">
            <label for="mes_referencia">Mês Referencia</label>
            <Calendar id="mes_referencia"  view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia" required="true" autofocus />

          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="this.ModalFatura = false" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="Salvar()" />
          </template>
        </Dialog>




        <!-- Modal deletar varias faturas -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="fatura">Apagar Tipos de Concessionarias selecionados?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>



      </div>
    </div>
  </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';

export default {
  mixins: [funcoes],
  data() {
    return {
      filtros: [],
      ModalFiltros: false,
      faturas: [],
      fatura: {},
      selectImplantacoes: null,
      ModalFatura: false,

      medidor: {},
      modalEditMedidor: false,
      medidorDialog: false,

      itens_faturados: {},
      modalEditItens: false,

      dados_faturas: {},
      modalEditDados: false,

      dados_fiscais: {},
      modalEditDadosFiscais: false,

      dados_tributos: {},
      modalEditDadosTributos: false,

      deleteModalFatura: false,
      deleteMedidorDialog: false,
      deleteDadosDialog: false,
      deleteDadosFiscaisDialog: false,
      deleteDadosTributosDialog: false,
      deleteItensFaturadosDialog: false,
      deleteProductsDialog: false,

      filters: {},
      submitted: false,

      tipos_empresas: [],
      desc_tipos_empresas: [],
      tipo_insumo: [],
      desc_tipo_insumo: [],
      tipos_modalidades: [],
      tipos_concess: [],
      tipos_pagamentos: [],
      tipos_classe_tarifaria: [],
      tipos_subclasse: [],
      tipos_tensao: [],
      tipos_ligacao: [],

    };
  },

  created() {
    this.initFilters();
  },
  mounted() {
    // funçoes importadas do arquivo funcoes.js
    this.listarFaturas();
    //this.listarTipoInsumo();
    this.listarEmpresas();
    //this.listarModalidadesTarifarias();
    //this.listarTiposConcessionarias();
    //this.listarPagamento();
    //this.listarClasseTarifaria();
    //this.listarSubclasse();
    //this.listarTensao();
    //this.listarLigacao();



  },


  methods: {
    openNew() {
      this.fatura = {};
      this.submitted = false;
      this.ModalFatura = true;
    },

    abrirFiltros() {
      this.ModalFiltros = true;
    },

    editarFatura(fatura) {
      this.ModalFatura = true;
      this.fatura = { ...fatura };
    },

    editDadosMedidor(medidor) {
      this.modalEditMedidor = true;
      this.medidor = { ...medidor };
    },

    editDadosFiscais(dados_fiscais) {
      this.modalEditDadosFiscais = true;
      this.dados_fiscais = { ...dados_fiscais };
    },

    editDadosTributos(dados_tributos) {
      this.modalEditDadosTributos = true;
      this.dados_tributos = { ...dados_tributos };
    },

    editItensFaturados(itens_faturados) {
      this.modalEditItens = true;
      this.itens_faturados = { ...itens_faturados };
    },

    editDadosFatura(dados_faturas) {
      this.modalEditDados = true;
      this.dados_faturas = { ...dados_faturas };
    },

    hideDialog() {
      this.ModalFatura = false;
      this.submitted = false;
    },

    addDadosMedidor(medidor) {
      this.modalEditMedidor = true;
      this.medidor.id_fatura = medidor.id;
    },

    addItensFaturados(itens_faturados) {
      this.modalEditItens = true;
      this.itens_faturados.id_fatura = itens_faturados.id;
    },




    // criar/editar fatura
    salvarFatura() {
      this.submitted = true;

      if (this.fatura.id) {
        // Ediçao de fatura
        this.axios
          .post("/faturas/editar", this.fatura)
          .then(() => {

            // console.log(this.fatura)

            this.ModalFatura = false;
            this.fatura = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova fatura
        this.axios
          .post("/faturas/adicionar", this.fatura)
          .then(() => {

            // alert(response.data.data)

            this.ModalFatura = false;
            this.fatura = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarMedidorFatura() {
      this.submitted = true;
      console.log('começa')
      console.log(this.medidor)
      console.log('termina')

      if (this.medidor.id) {
        // Ediçao de medidor
        console.log('entrou editar')
        this.axios
          .post("/faturas/medidor/editar", this.medidor)
          .then(() => {

            // console.log(this.medidor)

            this.modalEditMedidor = false;
            this.medidor = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova medidor
        console.log('entrou aqui')
        console.log(this.medidor)
        this.axios
          .post("/faturas/medidor/adicionar", this.medidor)
          .then(() => {

            // alert(response.data.data)

            this.modalEditMedidor = false;
            this.medidor = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarItensFaturadosFatura() {
      this.submitted = true;

      if (this.itens_faturados.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/itens-faturados/editar", this.itens_faturados)
          .then(() => {

            // console.log(this.itens_faturados)

            this.modalEditItens = false;
            this.itens_faturados = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova itens_faturados
        this.axios
          .post("/faturas/itens-faturados/adicionar", this.itens_faturados)
          .then(() => {

            // alert(response.data.data)

            this.modalEditItens = false;
            this.itens_faturados = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarDadosFatura() {
      this.submitted = true;

      if (this.dados_faturas.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/dados/editar", this.dados_faturas)
          .then(() => {

            // console.log(this.dados_faturas)

            this.modalEditDados = false;
            this.dados_faturas = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova dados_faturas
        this.axios
          .post("/faturas/dados/adicionar", this.dados_faturas)
          .then(() => {

            // alert(response.data.data)

            this.modalEditDados = false;
            this.dados_faturas = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarDadosFiscaisFatura() {
      this.submitted = true;

      if (this.dados_fiscais.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/dados-fiscais/editar", this.dados_fiscais)
          .then(() => {

            // console.log(this.dados_fiscais)

            this.modalEditDados = false;
            this.dados_fiscais = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova dados_fiscais
        this.axios
          .post("/faturas/dados-fiscais/adicionar", this.dados_fiscais)
          .then(() => {

            // alert(response.data.data)

            this.modalEditDados = false;
            this.dados_fiscais = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarDadosTributosFatura() {
      this.submitted = true;

      if (this.dados_tributos.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/dados-tributos/editar", this.dados_tributos)
          .then(() => {

            // console.log(this.dados_tributos)

            this.modalEditDadosTributos = false;
            this.dados_tributos = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova dados_tributos
        this.axios
          .post("/faturas/dados-tributos/adicionar", this.dados_tributos)
          .then(() => {

            // alert(response.data.data)

            this.modalEditDadosTributos = false;
            this.dados_tributos = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },







    apagarFaturasModal(fatura) {
      this.fatura = fatura;
      this.deleteModalFatura = true;
    },

    apagarDadosMedidorModal(medidor_faturas) {
      this.medidor_faturas = medidor_faturas;
      this.deleteMedidorDialog = true;
    },

    apagarDadosFiscaisModal(dados_fiscais) {
      this.dados_fiscais = dados_fiscais;
      this.deleteDadosFiscaisDialog = true;
    },

    apagarDadosTributosModal(dados_tributos) {
      this.dados_tributos = dados_tributos;
      this.deleteDadosTributosDialog = true;
    },

    apagarDadosFaturaModal(dados_faturas) {
      this.dados_faturas = dados_faturas;
      this.deleteDadosDialog = true;
    },

    apagarItensFaturadosModal(itens_faturados) {
      this.itens_faturados = itens_faturados;
      this.deleteItensFaturadosDialog = true;
    },

    // botao de excluir selecionados
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },

    apagarFaturas() {
      this.axios
        // console.log(this.concessionaria)
        .post("/faturas/apagar", this.fatura)
        .then(() => {

          this.deleteModalFatura = false;
          this.fatura = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Fatura apagada com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarItensFaturados() {
      this.axios
        // console.log(this.concessionaria)
        .post("/faturas/itens-faturados/apagar", this.itens_faturados)
        .then(() => {

          this.deleteItensFaturadosDialog = false;
          this.itens_faturados = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Item apagado com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDadosMedidor() {
      this.axios
        // console.log(this.concessionaria)
        .post("/faturas/medidor/apagar", this.medidor_faturas)
        .then(() => {

          this.deleteMedidorDialog = false;
          this.medidor_faturas = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Medidor apagado com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDados() {
      this.axios
        // console.log(this.concessionaria)
        .post("/faturas/dados/apagar", this.dados_faturas)
        .then(() => {

          this.deleteDadosDialog = false;
          this.dados_faturas = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Medidor apagado com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDadosFiscais() {
      this.axios
        // console.log(this.concessionaria)
        .post("/faturas/dados-fiscais/apagar", this.dados_fiscais)
        .then(() => {

          this.deleteDadosFiscaisDialog = false;
          this.dados_fiscais = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Dados Fiscais apagados com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDadosTributos() {
      this.axios
        // console.log(this.concessionaria)
        .post("/faturas/dados-tributos/apagar", this.dados_tributos)
        .then(() => {

          this.deleteDadosTributosDialog = false;
          this.dados_tributos = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Dados Tributos apagados com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

















    deleteSelectedProducts() {
      this.faturas = this.faturas.filter(
        (val) => !this.selectImplantacoes.includes(val)
      );

      let obj = this.selectImplantacoes;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/faturas/apagar", obj[key])
          .then(() => {
            this.deleteModalFatura = false;
            this.fatura = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "faturas apagadas com sucesso!",
              life: 3000,
            });
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectImplantacoes = null;
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },


    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

  },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>